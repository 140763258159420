//export const API_URL = 'https://bookinguat.dresort.com.sg:8888/api';
//export const API_URL = 'http://local.dresortlive/api';
export const API_URL = 'https://booking.dresort.com.sg:8888/api';
// export const API_URL = 'https://dr.aidencreative.com:8888/api';
// export const API_URL = 'http://local.dresort:8082/api';
//export const API_URL = 'https://vapt.dresort.com.sg:8888/api';

//export const WEBSITE_URL = 'https://bookinguat.dresort.com.sg/';
export const WEBSITE_URL = 'https://booking.dresort.com.sg/';
// export const WEBSITE_URL = 'http://localhost:3000/';
// export const WEBSITE_URL = 'https://dr.aidencreative.com/';
//export const WEBSITE_URL = 'https://vapt.dresort.com.sg/';

export const MAIN_WEBSITE_URL = 'https://dresort.com.sg/';

// export const ENETS_API_URL = 'https://uat2.enets.sg/';
export const ENETS_API_URL = 'https://www2.enets.sg/';

export const API_ACCESS_KEY = '367b83d11c08d1b19e96a8ee2a8621a1'; // LIVE
// export const API_ACCESS_KEY = '84de957620bc46ec87f544db956e0f0b';

export const IS_DEVELOPMENT = true;
export const IS_ALLOW_LINK_ACCESSIBLE_TO_LOGIN = false;

export const ADDON_TYPE_DAILY = 'daily';
export const ADDON_TYPE_STAY_PERIOD = 'stay_period';

export const ROOMS_LEFT_LIMIT = 5;

export const FIRST_LOAD_TOTAL_ROOMS = 3;
export const FIRST_LOAD_TOTAL_RATES = 3;

export const KEY_ROOM_NAME = 'dresRoomN';
export const KEY_ROOM_CODE = 'dresRoomC';
export const KEY_RATE_CODE = 'dresRateC';
export const KEY_CHECK_IN_DATE = 'dresChkIn';
export const KEY_CHECK_OUT_DATE = 'dresChkOut';
export const KEY_TOTAL_NIGHT = 'dresNight';
export const KEY_TOTAL_ROOMS = 'dresRoom';
export const KEY_TOTAL_ADULT = 'dresAd';
export const KEY_TOTAL_CHILDREN = 'dresCh';
export const KEY_SPECIAL_CODE_TYPE = 'dresSCType';
export const KEY_SPECIAL_CODE = 'dresSCCode';
export const KEY_TOTAL_ROOM_RATE_AND_PACKAGES = 'dresTtlAmt';
export const KEY_TOTAL_TAXES_AND_FEES = 'dresTtlTF';
export const KEY_TAX_INCLUSIVE = 'dresTXI';
export const KEY_GRAND_TOTAL = 'dresGrTtl';
export const KEY_SPECIAL_REQUESTS = 'dresSpcRqs';
export const KEY_ADDONS = 'dADon';
export const KEY_SELECTED_ADDON = 'dspack';
export const KEY_ADDON_QTY = 'daq';
export const KEY_ADDON_CHARGES = 'dac';
export const KEY_ADDON_CHARGES_BREAKDOWN = 'dacb';
export const KEY_TOTAL_PACKAGES_AMOUNT = 'dpacka';
export const KEY_TOTAL_PACKAGE_TAX_AND_FEES = 'dtptnf';
export const KEY_ACCESS_CODE = 'dresrot';
export const KEY_IS_MEMBER = 'dmem';
export const KEY_CHARGE_BREAKDOWN = 'dcb';
export const KEY_CARD_NO = 'dcbsk';
export const KEY_POLICY = 'dcpol';
export const KEY_SELECTED_RATE = 'dcrt';

export const KEY_PAYMENT_TOTAL = 'dptot';
export const KEY_PAYMENT_UNIQUE_ID = 'dud';

export const KEY_CONTENT = 'dcnt';
export const KEY_MIN_BOOKING_AMOUNT = 'kmba';

export const KEY_INCLUSIVE_PACKAGE = 'ipck';

export const MAX_ADULT_CHILDREN = 4;
export const MAX_ROOM = 5;

export const MAX_WWW_TICKETS_PER_ROOM = 10;

export const KEY_UPDATE_TOKEN = 'dut';
export const KEY_UPDATE_BOOKING_ID = 'dubi';
export const KEY_IS_MEMBER_PREVIOUS_BOOKING = 'dimpb';
export const KEY_MODIFY_BOOKING_HAS_LOADED = 'dmbhl';
export const KEY_TEMP_MODIFY_BOOKING_DATA = 'tmbd';

export const KEY_PAY_FOR_ADDON = 'dpfa';
export const KEY_AMEND_ADDON = 'dkaa';

export const KEY_AUTHORISED_DOMAIN = 'dad';
export const KEY_GST_PERCENTAGE = 'dgst';

export const CURRENT_GST = 9;
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileView } from '../../misc/helper_functions';
import { _getLocalStorage, _formatDateCheckInOut, _formatDecimalAmount, _formatDate } from '../../functions/helper_functions';
import { _loading } from '../../functions/message_functions';
import $ from 'jquery';
import moment from 'moment';

import DOMPurify from 'dompurify';

import * as allAPI from '../../apis/all';
import * as config from '../../const/config';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

var lastHeight = 0;
var lastOffSet = 0;

const generalInformationContent = () => (
		<ol style={{textAlign:'left',padding:'10px 20px 0px 20px'}}>
				<li>{/*1*/}
						In compliance with the Personal Data Protection Commission Singapore (PDPC)’s advisory guidelines on NRIC numbers issued on 31 August 2018, with effect from 1 September 2019, D’Resort will no longer accept check-ins on behalf of someone else. Registered guests are required to check-in personally for their stay.
				</li>
				<li>{/*2*/}
						Guests may not move furnishings or interfere with the electrical network or any other installations in the rooms or on the premises of D’Resort without the consent of the Management. Any damage to the room and the resort's fixtures, fittings and furnishings will be the responsibility of the guests and will be charged accordingly. A minimum $100 cleaning fee will be imposed for excessively messy or dirty rooms.
				</li>
				<li>{/*3*/}
						Smoking is only permitted at designated areas in D'Resort. All other premises in D'Resort have a non-smoking policy and $200 will be charged to your room for guests in violation of this.
				</li>
				<li>{/*4*/}
						In the event of false fire alarm activated by guests, a charge of $500 will be imposed.
				</li>
				<li>{/*5*/}
						Catering of food is only allowed through our appointed caterer. No other caterers are allowed in the resort premises. Catering venue will be advised accordingly.
				</li>
				<li>{/*6*/}
					For safety reasons, the charging of Portable Mobility Devices (PMDs) within D'Resort is not allowed.
				</li>
				<li>{/*7*/}
						No decorations or fixtures from external vendors are allowed in the room. Please email <a href="mailto:decorations@ntucclub.com.sg">decorations@ntucclub.com.sg</a> for more information on the appointed vendors. We reserved the rights to ask guest to remove any unauthorised decorations or fixtures. Should there be any decorations that damage the fittings, charges will apply.
				</li>
				<li>{/*8*/}
						Maximum guests permitted to stay in the room are based on room type and bedding configuration. For your safety, please abide strictly with the maximum room occupancy due to the Fire & Safety Code as stipulated by the SCDF Fire Code Review Committee and the FSSD Standing Committee. All visitors are to leave the resort premises after 11pm and the management reserves all rights to escort out any additional persons not complying with the Terms & Conditions.
				</li>
				<li>{/*9*/}
						Please be informed that the Goods and Services Tax (GST) will increase from 8% to 9% from 1 January 2024 onwards. The GST rate at 9% will be imposed on payments and transactions made on or after 1 January 2024.
				</li>
		</ol>
);

const styles = {
    subTitle:{
        textAlign:'left',
        margin:'15px 0px 0px 20px',
        fontWeight:'bold'
    },
    privacyContentFirstTitle:{
        fontWeight:'bold',
    },
    privacyContentRestTitle:{
        fontWeight:'normal',
    },
		policyText:{
				marginLeft: '20px'
		}
}

class Book extends React.Component{
	constructor(props){
			super(props);

			let tempModifyData = _getLocalStorage(config.KEY_TEMP_MODIFY_BOOKING_DATA);

			if ( tempModifyData ) {
					tempModifyData = tempModifyData.split(";");

					localStorage.setItem(config.KEY_UPDATE_BOOKING_ID, tempModifyData[0]);
					localStorage.setItem(config.KEY_UPDATE_TOKEN, tempModifyData[1]);
			}

			let totalRateAndPackages = _getLocalStorage(config.KEY_TOTAL_ROOM_RATE_AND_PACKAGES);
			let totalTaxesAndFees = _getLocalStorage(config.KEY_TOTAL_TAXES_AND_FEES);
			let totalAddons = _getLocalStorage(config.KEY_TOTAL_PACKAGES_AMOUNT);
			let totalAddonsTax = _getLocalStorage(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES);
			let grandTotal = _getLocalStorage(config.KEY_GRAND_TOTAL);
			let addons = _getLocalStorage(config.KEY_ADDONS);
			let totalRooms = _getLocalStorage(config.KEY_TOTAL_ROOMS);
			let totalAdult = _getLocalStorage(config.KEY_TOTAL_ADULT);
			let totalChildren = _getLocalStorage(config.KEY_TOTAL_CHILDREN);
			let specialCodeType = _getLocalStorage(config.KEY_SPECIAL_CODE_TYPE);
			let specialCode = _getLocalStorage(config.KEY_SPECIAL_CODE);
			let isLongTermCode = specialCodeType == 'Long Term Code';
			let addonCharges = _getLocalStorage(config.KEY_ADDON_CHARGES);
			let inclusivePackages = _getLocalStorage(config.KEY_INCLUSIVE_PACKAGE);
			let addonChargeBreakdown = _getLocalStorage(config.KEY_ADDON_CHARGES_BREAKDOWN);
			let updateToken = _getLocalStorage(config.KEY_UPDATE_TOKEN);
			let modifyBookingID = _getLocalStorage(config.KEY_UPDATE_BOOKING_ID);
			let authorisedDomain = _getLocalStorage(config.KEY_AUTHORISED_DOMAIN);
			let isShowAddonBreakdown = [];
			let hasInclusivePackage = false;

			if ( totalRateAndPackages ) { totalRateAndPackages = parseFloat(totalRateAndPackages.replace(/,/, "")); }
			if ( totalTaxesAndFees ) { totalTaxesAndFees = parseFloat(totalTaxesAndFees.replace(/,/, "")); }
			if ( totalAddons ) { totalAddons = parseFloat(totalAddons.replace(/,/, "")); } else { totalAddons = 0; }
			if ( totalAddonsTax ) { totalAddonsTax = parseFloat(totalAddonsTax.replace(/,/, "")); }
			if ( grandTotal ) { grandTotal = parseFloat(grandTotal.replace(/,/, "")); }
			if ( addons ) { addons = JSON.parse(addons); }

			if ( totalRateAndPackages === null || totalTaxesAndFees === null || grandTotal === null ) {
					window.location.href = config.WEBSITE_URL;
			}

			let specialRequests = JSON.parse(_getLocalStorage(config.KEY_SPECIAL_REQUESTS));
			let preference = '';
			let roomPreferences = [];
			let bedPreferences = [];
			for ( let specialRequestId in specialRequests ) {
					preference = specialRequests[specialRequestId];

					if ( !this.checkIsBedPreference(preference) ) {
							roomPreferences[specialRequestId] = preference;
					}
					else {
							bedPreferences[specialRequestId] = preference;
					}
			}

			roomPreferences = this.filter_array(roomPreferences);
			bedPreferences = this.filter_array(bedPreferences);

			// Initial qty for add-ons
			for ( let index in addons ) {
					let addon = addons[index];
					isShowAddonBreakdown[addon.id] = false;
			}

			let summaryAddonsCharges = new Array();
			if ( addonCharges ) {
					addonCharges = JSON.parse(addonCharges);

					for ( let index = 0; index < addonCharges.length; index++ ) {
							if ( addonCharges[index] ) {
									summaryAddonsCharges[addonCharges[index].id] = {};
									summaryAddonsCharges[addonCharges[index].id].title = addonCharges[index].title;
									summaryAddonsCharges[addonCharges[index].id].amount = addonCharges[index].amount;
							}
					}
			}
			//console.log(summaryAddonsCharges);

			let inclusivePackagesDisplay = '';
			if ( inclusivePackages && inclusivePackages !== '[]' && inclusivePackages.length > 0 ) {
					hasInclusivePackage = true;
					inclusivePackages = JSON.parse(inclusivePackages);

					for ( let index in inclusivePackages ) {
							inclusivePackagesDisplay += "<br />" + inclusivePackages[index].name;
					}

					inclusivePackagesDisplay = inclusivePackagesDisplay.substring("<br />".length);
			}

			let isRetrieveBooking = false;
			if ( updateToken !== null && modifyBookingID !== null ) {
					this.props.retrieveBookingWithToken(modifyBookingID, updateToken);

					isRetrieveBooking = true;
			}

			if ( authorisedDomain !== undefined && authorisedDomain !== 'undefined' && authorisedDomain !== '[]' && authorisedDomain.length > 0 ) {
					authorisedDomain = JSON.parse(authorisedDomain);
			}
			else {
					authorisedDomain = '';
			}

			console.log("Book constructor");
			console.log(modifyBookingID + " " + updateToken);

			this.state={
					checkInDate: _getLocalStorage(config.KEY_CHECK_IN_DATE),
					checkOutDate: _getLocalStorage(config.KEY_CHECK_OUT_DATE),
					totalNight: _getLocalStorage(config.KEY_TOTAL_NIGHT),
					roomCharge: isLongTermCode ? 0 : totalRateAndPackages,
					chargeBreakdown: _getLocalStorage(config.KEY_CHARGE_BREAKDOWN),
					totalAddons: totalAddons,
					totalAddonsTax: totalAddonsTax !== null ? parseFloat(totalAddonsTax) : 0,
					totalRooms: totalRooms,
					tax: isLongTermCode ? 0 : totalTaxesAndFees,
					grandTotal: isLongTermCode ? 0 : grandTotal,
					roomName: _getLocalStorage(config.KEY_ROOM_NAME),
					specialRequests: specialRequests,
					timeArrival: "I don't know",
					name: '',
					guestName: '',
					guestEmail: '',
					guestIDD: '+65',
					guestMobile: '',
					roomPreferences: '',
					bedPreference: [],
					specialRequest: [],
					isBookingForOther: false,
					comments: '',
					isAllowedMakePayment: false,
					isAgreeTermPrivacyPolicy: false,
					roomPreferences: roomPreferences,
					bedPreferences: bedPreferences,
					addons: addons,
					isSubmitBooking: false,
					isEmailValid:true,
					isEmailAuthorised: true,
					isGuestNameValid: true,
					isShowChargeBreakdown: false,
					isShowAddonBreakdown: isShowAddonBreakdown,
					errorMessage: null,
					totalAdult: totalAdult,
					totalChildren: totalChildren !== null ? totalChildren : 0,
					specialCodeType: specialCodeType !== null ? specialCodeType : null,
					specialCode: specialCode !== null ? specialCode : null,
					summaryAddonsCharges: summaryAddonsCharges,
					inclusivePackages: inclusivePackages !== null ? inclusivePackages : null,
					inclusivePackagesDisplay: inclusivePackagesDisplay !== null ? inclusivePackagesDisplay : null,
					addonChargeBreakdown: addonChargeBreakdown !== null ? JSON.parse(addonChargeBreakdown) : {},
					hasInclusivePackage: hasInclusivePackage,
					updateToken: updateToken,
					modifyBookingID: modifyBookingID,
					modifyBookingDetail: null,
					isRetrieveBooking: isRetrieveBooking,
					isLongTermCode: isLongTermCode,
					authorisedDomain: authorisedDomain,
					mailConsentEmail: false,
					mailConsentText: false,
					mailConsentCall: false
			}

			this.handleData = this.handleData.bind(this);
			this.handleCheckBox = this.handleCheckBox.bind(this);
			this.handleRoomPreferences = this.handleRoomPreferences.bind(this);
			this.handleBedPreferences = this.handleBedPreferences.bind(this);
			this.handleCancelModifying = this.handleCancelModifying.bind(this);
			//this.handleModifyBack = this.handleModifyBack.bind(this);
	}

		filter_array(test_array) {
				let index = -1;
				const arr_length = test_array ? test_array.length : 0;
				let resIndex = -1;
				const result = [];

				while (++index < arr_length) {
						const value = test_array[index];

						if (value) {
								result[++resIndex] = value;
						}
				}

				return result;
		}

		componentDidMount() {
				document.addEventListener('scroll',this.handleScroll);
				window.scrollTo(0, 0);
				$("#login").hide();
		}

		componentWillUnmount(){
				document.removeEventListener('scroll',this.handleScroll);

				let tempModifyData = _getLocalStorage(config.KEY_TEMP_MODIFY_BOOKING_DATA);

        if ( tempModifyData ) {
            tempModifyData = tempModifyData.split(";");

            localStorage.setItem(config.KEY_UPDATE_BOOKING_ID, tempModifyData[0]);
            localStorage.setItem(config.KEY_UPDATE_TOKEN, tempModifyData[1]);
        }
		}

		handleCheckBox(event) {
				this.setState({ [event.target.name]: event.target.checked });
				this.checkAllowedMakePayment(event);
		}

		setTimeOfArrival(event) {
				this.setState({ timeArrival: event.target.value });
		}

		checkAllowedMakePayment = (event) => {
				let name = event.target.name === 'name' ? event.target.value : this.state.name.trim();
				let guestEmail = event.target.name === 'guestEmail' ? event.target.value : this.state.guestEmail.trim();
				let guestIDD = event.target.name === 'guestIDD' ? event.target.value : this.state.guestIDD.trim();
				let guestMobile = event.target.name === 'guestMobile' ? event.target.value : this.state.guestMobile.trim();
				let guestName = event.target.name === 'guestName' ? event.target.value : this.state.guestName.trim();
				let isBookingForOther = event.target.name === 'isBookingForOther' ? event.target.checked : this.state.isBookingForOther;
				let isAgreeTermPrivacyPolicy = event.target.name === "isAgreeTermPrivacyPolicy" ? event.target.checked : this.state.isAgreeTermPrivacyPolicy;

				if ( isAgreeTermPrivacyPolicy && name !== "" && guestEmail !== "" && guestIDD !== "" && guestMobile !== "" && this.checkEmailValidation(guestEmail) && this.checkEmailAuthorisation(guestEmail) && this.checkNameValidation() ) {
						if ( isBookingForOther && guestName !== "" ) {
								this.setState({ isAllowedMakePayment: true });
						}
						else if ( !isBookingForOther ) {
								this.setState({ isAllowedMakePayment: true });
						}
						else {
								this.setState({ isAllowedMakePayment: false });
						}
				}
				else {
						this.setState({ isAllowedMakePayment: false });
				}
		}

		handleData(event,target) {
			event.persist();
			const iddReg = /^\+?\d*$/;
			const setTheData = () => {
				this.setState({ [event.target.name]: event.target.value },()=>{
					this.checkAllowedMakePayment(event);
				});
			}
			if(target==='mobile'){
				if(event.target.validity.valid){
					setTheData();
				}
			}else if(target==='IDD'){
				if(iddReg.test(event.target.value)){
					setTheData();
				}
			}
			else{
				setTheData();
			}
		}

		handleRoomPreferences(event) {
				let roomPreferences = this.state.specialRequest;

				if ( event.target.checked ) {
						roomPreferences.push(event.target.value);
				}
				else {
						roomPreferences.splice(roomPreferences.indexOf(event.target.value), 1);
				}

				//console.log(roomPreferences);

				this.setState({ specialRequest: roomPreferences });
		}

		handleBedPreferences(event) {
				let bedPreference = this.state.bedPreference;
				bedPreference[event.target.name] = event.target.value;

				this.setState({ bedPreference: bedPreference });
		}

		makePayment() {
				/*console.log(this.state.name);
				console.log(this.state.guestEmail);
				console.log(this.state.guestIDD);
				console.log(this.state.guestMobile);
				console.log(this.state.guestName);

				console.log(this.state.timeArrival);
				console.log(this.state.specialRequest);
				console.log(this.state.bedPreference);
				console.log(this.state.comments);*/

				let selectedAddons = _getLocalStorage(config.KEY_SELECTED_ADDON);
				if ( selectedAddons ) { selectedAddons = JSON.parse(selectedAddons); }

				//console.log(this.state.grandTotal + " " + this.state.totalBBQPit);

				let deposit = 0;
				if ( this.state.modifyBookingDetail ) {
						deposit = this.state.modifyBookingDetail.grand_total;
				}

				this.props.book(this.state.checkInDate, this.state.checkOutDate, this.state.totalRooms,
						_getLocalStorage(config.KEY_TOTAL_ADULT), _getLocalStorage(config.KEY_TOTAL_CHILDREN),
						_getLocalStorage(config.KEY_RATE_CODE), _getLocalStorage(config.KEY_ROOM_CODE), this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax - deposit,
				    	this.state.name, this.state.guestEmail, this.state.guestIDD, this.state.guestMobile, this.state.guestName,
						this.state.timeArrival, this.state.specialRequest, this.state.bedPreference, this.state.comments,
						selectedAddons, _getLocalStorage(config.KEY_SPECIAL_CODE_TYPE), _getLocalStorage(config.KEY_SPECIAL_CODE),
						this.state.mailConsentEmail, this.state.mailConsentText, this.state.mailConsentCall,
						this.state.modifyBookingID, this.state.updateToken);

				this.setState({ isSubmitBooking: true, errorMessage: null });
		}

		componentWillReceiveProps(nextProps) {
				if ( this.state.isSubmitBooking && nextProps.uniqueID ) {
						this.setState({ isSubmitBooking: false });

						if ( nextProps.grandTotal <= 0 ) {
								this.props.history.push('/thankyou/' + nextProps.uniqueID);
						}
						else {
								localStorage.setItem(config.KEY_PAYMENT_TOTAL, nextProps.grandTotal);
								localStorage.setItem(config.KEY_PAYMENT_UNIQUE_ID, nextProps.uniqueID);

								this.props.history.push('/payment');
						}
				}
				else if ( this.state.isSubmitBooking && nextProps.error ) {
						window.scrollTo(0, 0);
						this.setState({ isSubmitBooking: false, errorMessage: nextProps.message });
				}

				if ( this.state.isRetrieveBooking && nextProps.retrieveBookingStatus ) {
						if ( nextProps.bookingDetail === undefined ) {
								localStorage.removeItem(config.KEY_UPDATE_TOKEN);
								localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);

								//this.props.history.push('/manageBooking');
								window.location.href = config.WEBSITE_URL;
						}
						else {
								this.setState({ isRetrieveBooking: false, modifyBookingDetail: nextProps.bookingDetail });

								let modifyBookingDetail = nextProps.bookingDetail;
								this.setState({ name: modifyBookingDetail.name, guestIDD: modifyBookingDetail.idd_code, guestMobile: modifyBookingDetail.mobile, guestEmail: modifyBookingDetail.email });
						}
				}
		}

		loopAddonsCharges() {
        let summaryAddonsCharges = this.state.summaryAddonsCharges;
				let addonChargeBreakdown = this.state.addonChargeBreakdown;
        let dataToReturn = [];

        for ( let id in summaryAddonsCharges ) {
            if ( addonChargeBreakdown[summaryAddonsCharges[id].title] ) {
                dataToReturn.push(
										<div id="addon" key={summaryAddonsCharges[id].title}>
		                    <p key={summaryAddonsCharges[id].title} className="strong pointer link addon-label" onClick={ this.showHideAddonChargeBreakdown.bind(this, id) }>
		                        { summaryAddonsCharges[id].title }
		                        <FontAwesomeIcon className={ !this.state.isShowAddonBreakdown[id] ? "rotate-180" : ""} icon="chevron-up"/>
		                    </p>

												<span className="addon-amount">S$ { summaryAddonsCharges[id].amount.toFixed(2)  }</span>

												{ this.state.isShowAddonBreakdown[id] ? <table className="table-charge-breakdown"><tbody>{this.loopAddonChargeBreakdown(summaryAddonsCharges[id].title)}</tbody></table> : null }
										</div>
                )
            }
        }

        return dataToReturn;
    }

		showHideAddonChargeBreakdown(id) {
        let isShowAddonBreakdown = this.state.isShowAddonBreakdown;
        isShowAddonBreakdown[id] = !isShowAddonBreakdown[id];

        this.setState({ isShowAddonBreakdown });
    }

		loopAddonChargeBreakdown(title) {
				let dataToReturn = [];
        let addonChargeBreakdown = this.state.addonChargeBreakdown;

				if ( addonChargeBreakdown[title] ) {
            for ( let addon in addonChargeBreakdown[title] ) {
                dataToReturn.push(
                    <tr key={ title + addon }>
                        <td width="60%">{ addon } { addonChargeBreakdown[title][addon].type == config.ADDON_TYPE_DAILY ? " x " +  addonChargeBreakdown[title][addon].qty : null }</td>
                        <td width="40%" className="text-right">S$ { (addonChargeBreakdown[title][addon].totalAmount).toFixed(2) }</td>
                    </tr>
                );
            }
        }

        return dataToReturn;
    }

		loopChargeBreakdown() {
				let dataToReturn = [];
				let charges = JSON.parse(this.state.chargeBreakdown);

				for ( let chargeDate in charges ) {
						dataToReturn.push(
								<tr key={chargeDate}>
										<td width="60%">{ _formatDate(chargeDate) }</td>
										<td width="40%" className="text-right">S$ { this.state.isLongTermCode ? "0.00" : charges[chargeDate] }</td>
								</tr>
						);
				}

				return dataToReturn;
		}

		handleShowHide = (target) => {
        let current = this.state[target];
        current ? $(`.${target}`).slideDown() : $(`.${target}`).slideUp();
        let icon = $(`.${target}-icon > svg`);
        console.log(icon);
        icon.animate({borderSpacing: current?0:180},{
            step: function(now,fx){
                $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                $(this).css('transform', 'rotate(' + now + 'deg)');
            },
            duration:'slow',
        },'linear')
        this.setState({[target]:!current});
		}

		setUpReservationPart = () => {
				const viewStatus = isMobileView();
				if(viewStatus){
						return(
								<div className="modal fade" id="reservation-modal" tabIndex="-1" role="dialog" aria-hidden="true">
										<div className="modal-dialog" role="document">
												<div className="modal-content">
														<div className="modal-body">
																{this.reservationBodyPart(viewStatus)}
														</div>
												</div>
										</div>
								</div>
							);
				}
				else {
						return this.reservationBodyPart(viewStatus);
				}
		}

		reservationBodyMobilePart = (status) => {
			let outstandingPayment = 0, nonRefundable = 0, deposit = 0;
			if ( this.state.modifyBookingDetail ) {
					deposit = this.state.modifyBookingDetail.grand_total;
					nonRefundable = this.state.modifyBookingDetail.grand_total - ( this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax );
					outstandingPayment = ( this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax < deposit ) ? 0 : (this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax - deposit);
			}

			return(
				<div id="reservation" className="mb-4">
					<h3>Reservation Summary</h3>
					<div className="d-inline-block">
						<p className="strong text-green">{ this.state.roomName }</p>

						<p className="strong">Check In</p>
						<p className="">{ _formatDateCheckInOut(this.state.checkInDate) }</p>
						<p className="strong">Check Out</p>
						<p className="">{ _formatDateCheckInOut(this.state.checkOutDate) }</p>

						<p className="strong">Reservation</p>
  					<p className="">{this.state.totalRooms} Room{ this.state.totalRooms > 1 ? 's' : '' }, {this.state.totalAdult} Adult{ this.state.totalAdult > 1 ? 's' : '' }{ this.state.totalChildren > 0 ? <span>, { this.state.totalChildren } { this.state.totalChildren > 1 ? 'Children' : 'Child' }</span>  : null }, { this.state.totalNight } Night{ this.state.totalNight > 1 ? 's' : '' }</p>

						{ this.state.hasInclusivePackage ? <p className="strong">Inclusive Of</p> : null }
            { this.state.hasInclusivePackage ? <p className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.inclusivePackagesDisplay) }}></p> : null }

						{ this.state.specialCodeType && this.state.specialCode ? <p className="strong">Special Code</p> : null }
            { this.state.specialCodeType && this.state.specialCode ? <p className="">{this.state.specialCodeType}, {this.state.specialCode}</p> : null }
					</div>
					<hr className="mmt-0" />
					<div id="grand-total">
						<p className="strong pointer link" onClick={() => { this.setState({ isShowChargeBreakdown: !this.state.isShowChargeBreakdown }) }}>
								Room Charges <span className="float-right">S$ { _formatDecimalAmount(this.state.roomCharge) }</span>
								<FontAwesomeIcon className={ !this.state.isShowChargeBreakdown ? "rotate-180" : ""} icon="chevron-up"/>
						</p>
						{ this.state.isShowChargeBreakdown ? <table className="table-charge-breakdown"><tbody>{this.loopChargeBreakdown()}</tbody></table> : null }

						{ this.state.totalAddons > 0 ? //Object.keys(this.state.addonChargeBreakdown).length > 0 ?
							<p>Add-on:</p>
						: null }
						{ this.loopAddonsCharges() }
						{/* this.state.totalBBQPit > 0 ? <p className="strong">BBQ Pit Charges <span className="float-right">S$ { _formatDecimalAmount(this.state.totalBBQPit) }</span></p> : null */}
						<p className="strong">Tax <span className="float-right">S$ { _formatDecimalAmount(this.state.tax + this.state.totalAddonsTax) }</span></p>

						{
							!this.state.modifyBookingDetail ?
							<p className="strong">Grand Total<span className="float-right">S$ { _formatDecimalAmount(this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax) }</span></p>
							: null
						}

						{
							this.state.modifyBookingDetail ?
							<p className="strong">Deposit<span className="float-right">-S$ { _formatDecimalAmount(deposit) }</span></p>
							:
							null
						}

						{
							nonRefundable > 0 ?
							<p className="strong text-red">Non-Refundable Amount<span className="float-right">S$ { _formatDecimalAmount( nonRefundable ) }</span></p>
							:
							null
						}

						{ this.state.modifyBookingDetail ? <hr /> : null }

						{
							this.state.modifyBookingDetail ?
							<p className="strong">Outstanding Payment<span className="float-right">S$ { _formatDecimalAmount( outstandingPayment ) }</span></p>
							:
							null
						}
					</div>
					<hr/>

					<div className="row mt-2">
						<div className="col-md-12">
							{ config.IS_DEVELOPMENT ?
							<div>
								<label className="form-check-label mail-consent">
									I would like to receive the latest updates on new campaigns, promotions, events and products and services from the NTUC Club Group via:
								</label>
								<div className="form-check custom-checkbox">
									<input name="mailConsentEmail" type="checkbox" id="mailConsentEmail" checked={this.state.mailConsentEmail} onChange={this.handleCheckBox } />
									<span className="checkmark"></span>
									<label className="form-check-label" htmlFor="mailConsentEmail">
											Email
									</label>
								</div>

								<br />

								<div className="form-check custom-checkbox">
									<input name="mailConsentText" type="checkbox" id="mailConsentText" checked={this.state.mailConsentText} onChange={this.handleCheckBox } />
									<span className="checkmark"></span>
									<label className="form-check-label" htmlFor="mailConsentText">
											Text Messages
									</label>
								</div>

								<br />

								<div className="form-check custom-checkbox">
									<input name="mailConsentCall" type="checkbox" id="mailConsentCall" checked={this.state.mailConsentCall} onChange={this.handleCheckBox } />
									<span className="checkmark"></span>
									<label className="form-check-label" htmlFor="mailConsentCall">
											Phone Calls
									</label>
								</div>

								<br />

								<label className="form-check-label mail-consent" style={{ fontSize: "12px", marginTop: "5px", fontStyle: 'italic' }}>
									Please note that your selection here shall not supersede any consents provided to us prior to this.
								</label>

								<br /><br />
							</div> : null }

							<div className="form-check custom-checkbox">
								<input name="isAgreeTermPrivacyPolicy" type="checkbox" id="isAgreeTermPrivacyPolicy" checked={this.state.isAgreeTermPrivacyPolicy} onChange={this.handleCheckBox } />
								<span className="checkmark"></span>
								<label className="form-check-label" htmlFor="isAgreeTermPrivacyPolicy">
										I have read and accepted the <a target="_blank"  data-toggle="modal" data-target="#general-information" href="/book" className="text-success">Terms &amp;	Conditions</a> and <a target="_blank" href="https://www.ntucclub.com/privacy-policy/" className="text-success">Privacy Policy</a>
								</label>
							</div>
						</div>
					</div>

					<button disabled={ !this.state.isAllowedMakePayment } title={ !this.state.isAllowedMakePayment ? "Please fill in all compulsory fields" : "" }
						className="btn-md btn btn-success btn-square mt-4" id="checkout" onClick={ this.makePayment.bind(this) }>
							{ ( this.state.isLongTermCode && this.state.totalAddons == 0 ) ? 'BOOK ROOM' : 'MAKE PAYMENT' }
					</button>


					{/*<div id="agreement" className="mt-4">
						<p>* This reservation is subject to the Terms & Conditions found <NavLink to="#">here</NavLink>.</p>
						<p>* The personal data you provide will be used, disclosed and (where appropriate) transferred overseas in accordance with our Privacy Policy and Cookies Policy found <NavLink to="#">here</NavLink>.</p>
						<p>* In order to proceed with this reservation, please indicate your agreement to the Terms & Conditions and the <NavLink to="#">Privacy policy</NavLink> by clicking above.</p>
					</div>*/}
				</div>
			)
		}

		reservationBodyPart = (status) => {
			let outstandingPayment = 0, nonRefundable = 0, deposit = 0;
			if ( this.state.modifyBookingDetail ) {
					deposit = this.state.modifyBookingDetail.grand_total;
					nonRefundable = this.state.modifyBookingDetail.grand_total - ( this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax );
					outstandingPayment = ( this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax < deposit ) ? 0 : (this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax - deposit);
			}

			return(
				<div id={`${status?'':'reservation'}`}>
					<div className="show-mobile" id="btn-modal-close" data-izimodal-close="" data-izimodal-transitionout="bounceOutDown">
						<em className="fas fa-times" data-toggle="tooltip" data-placement="top" title="" data-remove-id="1" data-dismiss="modal" data-original-title="Remove"></em>
					</div>
					<h3>Reservation Summary</h3>
					<div className="d-inline-block">
						<p className="strong text-green">{ this.state.roomName }</p>

						<p className="strong">Check In</p>
						<p className="">{ _formatDateCheckInOut(this.state.checkInDate) }</p>
						<p className="strong">Check Out</p>
						<p className="">{ _formatDateCheckInOut(this.state.checkOutDate) }</p>

						<p className="strong">Reservation</p>
  					<p className="">{this.state.totalRooms} Room{ this.state.totalRooms > 1 ? 's' : '' }, {this.state.totalAdult} Adult{ this.state.totalAdult > 1 ? 's' : '' }{ this.state.totalChildren > 0 ? <span>, { this.state.totalChildren } { this.state.totalChildren > 1 ? 'Children' : 'Child' }</span>  : null }, { this.state.totalNight } Night{ this.state.totalNight > 1 ? 's' : '' }</p>

						{ this.state.hasInclusivePackage ? <p className="strong">Inclusive Of</p> : null }
            { this.state.hasInclusivePackage ? <p className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.inclusivePackagesDisplay) }}></p> : null }

						{ this.state.specialCodeType && this.state.specialCode ? <p className="strong">Special Code</p> : null }
            { this.state.specialCodeType && this.state.specialCode ? <p className="">{this.state.specialCodeType}, {this.state.specialCode}</p> : null }
					</div>
					<hr className="mmt-0" />
					<div id="grand-total">
						<p className="strong pointer link" onClick={() => { this.setState({ isShowChargeBreakdown: !this.state.isShowChargeBreakdown }) }}>
								Room Charges <span className="float-right">S$ { _formatDecimalAmount(this.state.roomCharge) }</span>
								<FontAwesomeIcon className={ !this.state.isShowChargeBreakdown ? "rotate-180" : ""} icon="chevron-up"/>
						</p>
						{ this.state.isShowChargeBreakdown ? <table className="table-charge-breakdown"><tbody>{this.loopChargeBreakdown()}</tbody></table> : null }

						{ this.state.totalAddons > 0 ? // Object.keys(this.state.addonChargeBreakdown).length > 0 ?
							<p>Add-on:</p>
						: null }
						{ this.loopAddonsCharges() }
						{/* this.state.totalBBQPit > 0 ? <p className="strong">BBQ Pit Charges <span className="float-right">S$ { _formatDecimalAmount(this.state.totalBBQPit) }</span></p> : null */}
						<p className="strong">Tax <span className="float-right">S$ { _formatDecimalAmount(this.state.tax + this.state.totalAddonsTax) }</span></p>

						{
							!this.state.modifyBookingDetail ?
							<p className="strong">Grand Total<span className="float-right">S$ { _formatDecimalAmount(this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax) }</span></p>
							: null
						}

						{
							this.state.modifyBookingDetail ?
							<p className="strong">Deposit<span className="float-right">-S$ { _formatDecimalAmount(deposit) }</span></p>
							:
							null
						}

						{
							nonRefundable > 0 ?
							<p className="strong text-red">Non-Refundable Amount<span className="float-right">S$ { _formatDecimalAmount( nonRefundable ) }</span></p>
							:
							null
						}

						{ this.state.modifyBookingDetail ? <hr /> : null }

						{
							this.state.modifyBookingDetail ?
							<p className="strong">Outstanding Payment<span className="float-right">S$ { _formatDecimalAmount( outstandingPayment ) }</span></p>
							:
							null
						}
					</div>
					<hr/>

					<div className="row mt-2">
						<div className="col-md-12">
							{ config.IS_DEVELOPMENT ?
								<div>
									<label className="form-check-label mail-consent">
										I would like to receive the latest updates on new campaigns, promotions, events and products and services from the NTUC Club Group via:
									</label>
									<div className="form-check custom-checkbox">
										<input name="mailConsentEmail" type="checkbox" id="mailConsentEmail" checked={this.state.mailConsentEmail} onChange={this.handleCheckBox } />
										<span className="checkmark"></span>
										<label className="form-check-label" htmlFor="mailConsentEmail">
												Email
										</label>
									</div>

									<br />

									<div className="form-check custom-checkbox">
										<input name="mailConsentText" type="checkbox" id="mailConsentText" checked={this.state.mailConsentText} onChange={this.handleCheckBox } />
										<span className="checkmark"></span>
										<label className="form-check-label" htmlFor="mailConsentText">
												Text Messages
										</label>
									</div>

									<br />

									<div className="form-check custom-checkbox">
										<input name="mailConsentCall" type="checkbox" id="mailConsentCall" checked={this.state.mailConsentCall} onChange={this.handleCheckBox } />
										<span className="checkmark"></span>
										<label className="form-check-label" htmlFor="mailConsentCall">
												Phone Calls
										</label>
									</div>

									<br />

									<label className="form-check-label mail-consent" style={{ fontSize: "12px", marginTop: "5px", fontStyle: 'italic' }}>
										Please note that your selection here shall not supersede any consents provided to us prior to this.
									</label>

									<br /><br />
								</div> : null }

							<div className="form-check custom-checkbox">
								<input name="isAgreeTermPrivacyPolicy" type="checkbox" id="isAgreeTermPrivacyPolicy" checked={this.state.isAgreeTermPrivacyPolicy} onChange={this.handleCheckBox } />
								<span className="checkmark"></span>
								<label className="form-check-label" htmlFor="isAgreeTermPrivacyPolicy">
										I have read and accepted the <a target="_blank"  data-toggle="modal" data-target="#booking-tc-information" href="/book" className="text-success">Terms &amp;	Conditions</a> and <a target="_blank" href="https://www.ntucclub.com/privacy-policy/" className="text-success">Privacy Policy</a>
								</label>
							</div>
						</div>
					</div>

					{ (this.state.modifyBookingDetail && outstandingPayment == 0) ?
						<button disabled={ !this.state.isAllowedMakePayment } title={ !this.state.isAllowedMakePayment ? "Please fill in all compulsory fields" : "" }
							className="btn-md btn btn-success btn-square mt-4" id="checkout" onClick={ this.makePayment.bind(this) }>
								AMEND ROOM
						</button>

						:

						<button disabled={ !this.state.isAllowedMakePayment } title={ !this.state.isAllowedMakePayment ? "Please fill in all compulsory fields" : "" }
							className="btn-md btn btn-success btn-square mt-4" id="checkout" onClick={ this.makePayment.bind(this) }>
								{ ( this.state.isLongTermCode && this.state.totalAddons == 0 ) ? 'BOOK ROOM' : 'MAKE PAYMENT' }
						</button>
					}

					{/*<div id="agreement" className="mt-4">
						<p>* This reservation is subject to the Terms & Conditions found <NavLink to="#">here</NavLink>.</p>
						<p>* The personal data you provide will be used, disclosed and (where appropriate) transferred overseas in accordance with our Privacy Policy and Cookies Policy found <NavLink to="#">here</NavLink>.</p>
						<p>* In order to proceed with this reservation, please indicate your agreement to the Terms & Conditions and the <NavLink to="#">Privacy policy</NavLink> by clicking above.</p>
					</div>*/}
				</div>
			)
		}

		loopArrivalTime = () => {
				let dataToReturn = [];
				let startTime = 15;
				let endTime = 6;
				let nextDayLimit = 24;
				let isStop = false;
				let isNextDay = false;
				let time = '';

				let count = 30;

				while ( !isStop || count === 0 ) {
						if ( startTime === (nextDayLimit - 1) ) {
								time = startTime.toString().padStart(2, '0') + ":00 - 00:00";
						}
						else {
								time = startTime.toString().padStart(2, '0') + ":00 - " + (startTime + 1).toString().padStart(2, '0') + ":00" + ( isNextDay ? " (The Next Day)" : "" );
						}

						dataToReturn.push(
								<option key={ time } value={ time }>{ time }</option>
						);

						startTime++;

						if ( startTime === nextDayLimit ) {
								startTime = 0;
								isNextDay = true;
						}

						if ( startTime === endTime ) {
								isStop = true;
						}

						count--;
				}

				return dataToReturn;
		}

		checkIsBedPreference(preference) {
				let isBedReference = false;
				let bedPreferences = ["single", "double", "twin", "queen"];
				let bedPreference = '';
				let preferenceName = preference.name.toLowerCase();
				for ( let key in bedPreferences ) {
						bedPreference = bedPreferences[key];

						if ( preferenceName.indexOf(bedPreference) > -1 ) {
								isBedReference = true;
								break;
						}
				}

				return isBedReference;
		}

		loopRoomPreferences() {
				let preferences = [];
				let preference = '';
				for ( let specialRequestId in this.state.roomPreferences ) {
						preference = this.state.roomPreferences[specialRequestId];

						preferences.push(
								<div key={ specialRequestId } className="col-md-4">
									<div className="form-check custom-checkbox">
										<input type="checkbox" value={ preference.code } id={preference.name} onChange={ this.handleRoomPreferences } />
										<span className="checkmark"></span>
										<label className="form-check-label" htmlFor={preference.name}>
												{ preference.name }
										</label>
									</div>
								</div>
						);
				}

				return preferences;
		}

		loopBedPreferences(bedPreferences, index) {
				let preferences = [];
				let preference = '';
				let bedIcon = '';
				let preferenceName = '';
				let isTwin = false;
				let className = '';

				for ( let specialRequestId in bedPreferences ) {
						preference = bedPreferences[specialRequestId];
						preferenceName = preference.name.toLowerCase();
						isTwin = false;

						bedIcon = <em className="bed-icon large-bed"></em>;
						if ( preferenceName.indexOf("single") > -1 ) {
								bedIcon = <em className="bed-icon single-bed"></em>;
						}
						else if ( preferenceName.indexOf("double") > -1 ) {
								bedIcon = <em className="bed-icon large-bed"></em>;
						}
						else if ( preferenceName.indexOf("twin") > false ) {
								bedIcon = <em className="bed-icon twin-bed"></em>;
								isTwin = true;
						}

						className = 'form-check form-check-inline check-bed' + ( isTwin ? ' twin' : '' );

						preferences.push(
								<div key={ specialRequestId } className={ className }>
										<input className="form-check-input" type="radio"
												name={ index } id={ preference.name }
												value={ preference.code } onChange={ this.handleBedPreferences } />
										<label className="form-check-label" htmlFor={ preference.name }>
										{ bedIcon } { preference.name.replace("Request", "").replace("for", "").trim() }</label>
								</div>
						);
				}

				return preferences;
		}

		loopRoomBedPreferences() {
				//let preferences = [];
				let bedPreferences = []

				/*let preference = '';
				for ( let specialRequestId in this.state.specialRequests ) {
						preference = this.state.specialRequests[specialRequestId];

						if ( this.checkIsBedPreference(preference) ) {
								preferences[specialRequestId] = preference;
						}
				}*/

				// The selection of the bed preferences are according to the total rooms
				// OPERA Limitation
				//for ( let index = 0; index < this.state.totalRooms; index++ ) {
				let index = 0;
						bedPreferences.push(
								<div key={ index } className="form-group form-collapse">
									{/*<label>ROOM { index + 1 }</label>*/}
									<div className="addon-input">
										{ this.loopBedPreferences(this.state.bedPreferences, index) }
									</div>
								</div>
						);
				//}

				return bedPreferences;
		}

		showErrorMessage() {
				let errorMessageCount = 0;
				let errorMessage = this.state.errorMessage;

				errorMessage = errorMessage.map(i => {
						return <p key={errorMessageCount++}>{i}</p>
				});

				errorMessage.push(<p key={ errorMessageCount } style={{ textDecoration: 'underline' }}><NavLink to="/">Book other room</NavLink></p>);

				return errorMessage;
		}

		handleCancelModifying() {
				localStorage.removeItem(config.KEY_UPDATE_TOKEN);
				localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);

				this.props.history.push('/manageBooking');
		}

		handleScroll = (event) => {
			if(!isMobileView()){
				let target = 'reservation';
				let footerTopOffset = $('#footerPart').offset().top;
				let { offsetHeight } = document.getElementById(target);
				let bottomLimit = window.pageYOffset + offsetHeight + 50;
				if(!lastHeight && !lastOffSet){
								lastHeight = offsetHeight;
					lastOffSet = $(`#${target}`).offset().top;

								//console.log(lastHeight + " " + lastOffSet);
				}

						//console.log(window.pageYOffset + " " + lastHeight + " " + lastOffSet);

				let widthTarget = $(`#${target}`).outerWidth();
				if(window.pageYOffset < (lastOffSet)){
					$(`#${target}`).css({
						position:'relative',
						width:'',
					})
				}else if((window.pageYOffset > lastOffSet)  && (bottomLimit<footerTopOffset)){
					$(`#${target}`).css({
						position:'fixed',
						top:0,
						width:widthTarget
					})
				}else{
					$(`#${target}`).css({
						position:'relative',
						width:'',
						top:footerTopOffset - lastOffSet - offsetHeight - 25
					})
				}
			}
    	}
	checkEmailValidation = () => {
		const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailReg.test(this.state.guestEmail);
	}
	checkEmailAuthorisation = () => {
		let isAuthorisedDomain = true;
		if ( this.state.authorisedDomain != "" ) {
				isAuthorisedDomain = false;
				for ( let index = 0; index < this.state.authorisedDomain.length; index++ ) {
						if ( this.state.guestEmail.indexOf(this.state.authorisedDomain[index]) > -1 ) {
								isAuthorisedDomain = true;
						}
				}
		}

		return isAuthorisedDomain;
	}
	checkNameValidation = () => {
		const nameReg = /^([a-zA-Z\s\/',\-\.]*)$/;

		//console.log(this.state.name + " " + nameReg.test(this.state.name));
		return nameReg.test(this.state.name);
	}
	handleModifyBack = () => {
			let tempModifyData = _getLocalStorage(config.KEY_TEMP_MODIFY_BOOKING_DATA);

			if ( tempModifyData ) {
					tempModifyData = tempModifyData.split(";");

					localStorage.setItem(config.KEY_UPDATE_BOOKING_ID, tempModifyData[0]);
					localStorage.setItem(config.KEY_UPDATE_TOKEN, tempModifyData[1]);
			}
	}
	handleEmailBlur = () => {
		if(this.checkEmailValidation(this.state.guestEmail)){
			this.setState({isEmailValid:true});
		}
		else{
			this.setState({isEmailValid:false})
		}

		this.setState({ isEmailAuthorised: this.checkEmailAuthorisation(this.state.guestEmail) });
	}
	handleNameBlur = () => {
		if(this.checkNameValidation()){
			this.setState({isGuestNameValid:true});
		}else{
			this.setState({isGuestNameValid:false})
		}
	}
    render(){
				let ratePolicy = _getLocalStorage(config.KEY_POLICY);
				let ratePlanName = _getLocalStorage(config.KEY_SELECTED_RATE);

				let policyCancellationDate = '';
				if ( ratePolicy ) {
						ratePolicy = JSON.parse(ratePolicy);
						if ( ratePolicy.cancellation_days > 0 ) {
								policyCancellationDate = moment(this.state.checkInDate).add(-1 * ratePolicy.cancellation_days, 'days').format('ddd, DD MMM YYYY');
						}
				}

				let outstandingPayment = 0, nonRefundable = 0, deposit = 0;
				if ( this.state.modifyBookingDetail ) {
						deposit = this.state.modifyBookingDetail.grand_total;
						nonRefundable = this.state.modifyBookingDetail.grand_total - this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax;
						outstandingPayment = ( this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax < this.state.modifyBookingDetail.grand_total ) ? 0 : (this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax - this.state.modifyBookingDetail.grand_total);
				}

				//console.log(this.state.packages);
				return(
            <div>
								{ ( this.state.isSubmitBooking || this.state.isRetrieveBooking ) ? _loading() : null }
								<div id="book-step" className="no-banner">
											<div className="container">
													<div className="row">
															<div id="sort">
																	<div className="btn-group position-relative" id="sort-list">
																			&nbsp;
																	</div>
															</div>

															<div className="step prev" data-id="1">
																	<span style={{paddingTop:2}} className="number-count">1</span>Select Room & Dates
															</div>

															<div className="step prev" data-id="2">
																	<span style={{paddingTop:2}} className="number-count">2</span>Select Add-Ons
															</div>

															<div className="step active" data-id="3">
																	<span style={{paddingTop:1}} className="number-count">3</span>Guest Information & Payment
															</div>
															<div className="step next" data-id="4">
																	<span style={{paddingTop:1}} className="number-count">4</span>Confirmation
															</div>

															<div id="sort">
																	<div className="btn-group position-relative" id="sort-list">
																			&nbsp;
																	</div>
															</div>
													</div>
											</div>
									</div>

									{ (this.state.updateToken !== null && this.state.modifyBookingID !== null) ?
                    <div className="container maintenance">
                      <div className="row">
                        <div className="col-12 alert alert-warning mt-4">
                            You're currently modifying your booking. The Reservation ID is { this.state.modifyBookingID }.
                            <br />
                            <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={ this.handleCancelModifying.bind(this) }>Cancel</a>
                        </div>
                      </div>
                    </div>
                    : null }

									{isMobileView() ?
									(
											<div className="container" id="textStep">
													<div className="row">
															<p>Guest Information & Payment</p>
													</div>
											</div>
									) :
									null
									}
				<section id="total-reservation" className="show-mobile">
                    <div className="container">
                        <div className="row reservation">
                            <div className="col-6 left">

																		{
																			this.state.modifyBookingDetail ?
																			<p className="grand-total">
																					Outstanding Payment:<br /><span className="price">S$ { _formatDecimalAmount(outstandingPayment) }</span>
																			</p>
																			:
																			<p className="grand-total">
																					Grand Total:<br /><span className="price">S$ { _formatDecimalAmount(this.state.grandTotal + this.state.totalAddons + this.state.totalAddonsTax) }</span>
																			</p>
																		}

                                <p className="link" data-toggle="modal" data-target="#reservation-modal">View Summary</p>
                            </div>
                            <div  className={ !this.state.isAllowedMakePayment ? 'gray-out col-6 right' : 'col-6 right' }>
                                <p onClick={ this.state.isAllowedMakePayment ? this.makePayment.bind(this) : () => {} }>
																	{ ( this.state.isLongTermCode && this.state.totalAddons == 0 ) ? 'BOOK ROOM' : 'MAKE PAYMENT' }
																</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="main">
					<div className="container">
						<div className="row">
							<div className="col-md-8" id="checkout">
								<NavLink to={ (this.state.addons === null || this.state.addons.length === 0 ) ? '/' : '/addons' } onClick={this.handleModifyBack.bind(this)} className="link-back">&lt; Back</NavLink>

								{ this.state.errorMessage ?
									<div className="alert alert-danger mt-2">{ this.showErrorMessage() }</div>
								: null }

								<div className="checkout information">
									<div className="row">
										<div className="col-md-12">

											<h3>Guest Information</h3>
											<p className="text-green">In compliance with PDPC’s advisory guidelines on NRIC numbers, D’Resort no longer accept check-in on behalf. Registered guests are required to check-in personally for their stay.</p>
											<hr />
										</div>
										{/*<div className="col-md-5">
											<div className="">
												<div className="login">
													<p> <NavLink to="#">Login</NavLink> and earn up to 1,274 GC Points with this booking</p>
													<button className="btn btn-sm btn-success btn-square btn-login hide-mobile">LOGIN</button>
												</div>
											</div>
										</div>*/}
									</div>
									<div className="row mt-2">
										<div className="col-12 checkout-form">
											<div className="form-group">
												<label htmlFor="usr">Guest Name <span className="asterix">*</span></label>
												<input name="name" maxLength="40" type="text" className="form-control" value={ this.state.name } onBlur={ this.handleNameBlur } onChange={ this.handleData } placeholder="Full name as shown on Passport or NRIC" />
												{
													this.state.isGuestNameValid ? null :
													<p style={{
														marginTop: '7px',
														color: 'red',
														fontWeight: 'bold',
													}}>Guest Name is Invalid. Guest Name can only contains alphabet, space, and special characters such as /, ', -, ., and (,).</p>
												}
											</div>
											<div className="form-group">
												<label htmlFor="usr">Email <span className="asterix">*</span></label>
												<input onBlur={this.handleEmailBlur} name="guestEmail" type="email" className="form-control" value={ this.state.guestEmail } onChange={ this.handleData } placeholder="Provide accurate email to receive booking confirmation" />
												{
													this.state.isEmailValid ? null :
													<p style={{
														marginTop: '7px',
														color: 'red',
														fontWeight: 'bold',
													}}>Email is Invalid</p>
												}

												{
													( !this.state.isEmailValid || ( this.state.isEmailValid && this.state.isEmailAuthorised ) ) ? null :
													<p style={{
														marginTop: '7px',
														color: 'red',
														fontWeight: 'bold',
													}}>Email is not from authorised domain. Please use your company email address.</p>
												}
											</div>

											<div className="form-group mb-0">
												<label htmlFor="usr">Mobile <span className="asterix">*</span></label>
											</div>
											<div className="form-group input-group form-mobile">
												<div className="input-group-prepend">
													<input pattern="/^\+(?:[\d]*)/g" type="text" name="guestIDD" className="form-control" value={ this.state.guestIDD } placeholder="+IDD Code" maxLength="4" onChange={ (e) => this.handleData(e,'IDD') } />
												</div>
												<input pattern="[0-9]*" type="text" name="guestMobile" className="form-control" value={ this.state.guestMobile } maxLength="12" onChange={ (e) => this.handleData(e,'mobile') } />
											</div>

											{/*<div className="form-group">
													<div className="form-check custom-checkbox">
															<input name="isBookingForOther" className="" type="checkbox" id="isBookingForOther" onChange={ this.handleCheckBox } />
															<span className="checkmark"></span>
															<label className="form-check-label" htmlFor="isBookingForOther">
																	Please tick if you're making this booking for someone else.
															</label>
													</div>
											</div>*/}

											{ this.state.isBookingForOther ?
												<div className="form-group">
													<label htmlFor="usr">Guest Name</label>
													<input name="guestName" type="text" className="form-control" value={ this.state.guestName } onChange={ this.handleData } />
												</div> : null }

										</div>
									</div>
								</div>
								<div className="checkout optional">
									<div className="row">
										<div className="col-md-12">
											<h3>Booking Request</h3>
											<p>All requests are not guaranteed and subject to availability.</p>
											{/*<small className="text-success">All request are subject to availability.</small>*/}
											<hr/>
											<div className="container">
												<div className="row">
													<div className="col-12">
														<div className="form-collapse-title">
															<p className="title">ARRIVAL AND DEPARTURE DETAILS</p>
															<p onClick={()=>this.handleShowHide('data-toggle-1')} className="toggle-detail data-toggle-1-icon" data-toggle-id="1">
																<span className="text hide-mobile">{this.state['data-toggle-1']?'Show':'Hide'} Details</span>&emsp;
																<FontAwesomeIcon icon="chevron-up"/>
															</p>
														</div>
														<div className="form-group form-collapse data-toggle-1 mb-3" data-toggle-hide="1">
															<label>Estimated Time of Arrival</label>
															<div className="addon-input">
																<select className="custom-select" onChange={ this.setTimeOfArrival.bind(this) }>
																	<option value="I don't know">I don't know</option>
																	{ this.loopArrivalTime() }
																</select>
																Check-in time after 3pm, check out before 11am.
															</div>
														</div>
													</div>
												</div>
											</div>

											{ this.state.roomPreferences.length > 0 ?

											<div className="room-preferences">
												<hr className="mt-0" />
												<div className="container">
													<div className="row">
														<div className="col-12">
															<div className="form-collapse-title">
																<p style={{ marginBottom: '0px' }} className="title">OTHER REQUESTS</p>
																<p style={{ color: '#184e66', fontSize: '0.75em', fontWeight: '600', marginTop: '5px' }}>Subject to availability</p>
																<p onClick={()=>this.handleShowHide('data-toggle-2')} className="toggle-detail data-toggle-2-icon" data-toggle-id="2">
																	<span className="text hide-mobile">{this.state['data-toggle-2']?'Show':'Hide'} Details</span>&emsp;
																	<FontAwesomeIcon icon="chevron-up"/>
																</p>
															</div>
															<div className="col-12 pl-0 pr-0 data-toggle-2 mb-3" data-toggle-hide="2">
																<div className="row">
																	{ this.loopRoomPreferences() }
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

												: null }


											{ this.state.bedPreferences.length > 0 ?

											<div className="bed-preferences">
												<hr className="mt-0" />
												<div className="container">
													<div className="row">
														<div className="col-12">
															<div className="form-collapse-title">
																<p style={{ marginBottom: '0px' }} className="title">BED PREFERENCES</p>
																<p style={{ color: '#184e66', fontSize: '0.75em', fontWeight: '600', marginTop: '5px' }}>Subject to availability</p>
																<p onClick={()=>this.handleShowHide('data-toggle-3')} className="toggle-detail data-toggle-3-icon" data-toggle-id="3">
																	<span className="text hide-mobile">{this.state['data-toggle-3']?'Show':'Hide'} Details</span>&emsp;
																	<FontAwesomeIcon icon="chevron-up"/>
																</p>
															</div>
															<div className="col-12 pl-0 pr-0 data-toggle-3" data-toggle-hide="3">
																<div className="row">
																	<div className="col-12">
																			{ this.loopRoomBedPreferences() }
																	</div>
																	<div className="col-4">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

												: null }

											<hr className="hide-mobile mt-0"/>
										</div>
									</div>
								</div>
								<div className="checkout optional">
									<div className="row">
										<div className="col-md-12">
											<h3>Special Request</h3>
											<p>Please include details of your special request. Special requests are not guaranteed and may incur additional charges.</p>
											{/*<small className="text-success">All requests are subject to availability.</small>*/}
											<textarea name="comments" className="special-request" cols="30" rows="5" defaultValue={ this.state.comments } onChange={ this.handleData } maxLength="200"></textarea>
											<p className="text-area-counter"> Max. 200 characters </p>
										</div>
									</div>
								</div>

								{isMobileView() ?
								(
										this.reservationBodyMobilePart(isMobileView())
								)
								:
								null
								}

								{/* <div className="row mt-2 mb-4 show-mobile">
									<div className="col-md-12">
										<div className="form-check custom-checkbox">
											<input name="isAgreeTermPrivacyPolicy" type="checkbox" id="isAgreeTermPrivacyPolicy" checked={this.state.isAgreeTermPrivacyPolicy} onChange={this.handleCheckBox } />
											<span className="checkmark"></span>
											<label className="form-check-label" htmlFor="isAgreeTermPrivacyPolicy">
													I have read and accepted the <a target="_blank" data-toggle="modal" data-target="#general-information" href="/book" className="text-success">Terms &amp;	Conditions</a> and <a target="_blank" href="https://www.ntucclub.com/privacy-policy/" className="text-success">Privacy Policy</a>
											</label>
										</div>
									</div>
								</div> */}
							</div>
							<div className="col-md-4" id="sidebar">
								{ this.setUpReservationPart() }
							</div>
						</div>
					</div>
				</div>

						<div className="modal fade popUpModel" id="booking-tc-information" tabIndex="-1" role="dialog" aria-hidden="true">
							<div style={{maxWidth:1000}} className="modal-dialog" role="document">
									<div className="modal-content">
											<div className="modal-body">
													<div className="modal-header">
															<h4 className="modal-title">Need To Know</h4>
															<button type="button" className="close" data-dismiss="modal">&#10005;</button>
													</div>
													<div style={styles.subTitle}>
															Check-In
													</div>
													<ul>
															<li>Check-in after 3pm.</li>
															<li>Minimum check-in age: 18 years old.</li>
															<li>Registered Guests have to show their physical photo identification, credit card used for the booking and NTUC Member card (if applicable) upon check-in. Digital identification cards (Singpass) are not accepted.</li>
													</ul>

													<div style={styles.subTitle}>
															Check-Out
													</div>
													<ul>
															<li>Check-out before 11am.</li>
													</ul>

													<div style={styles.subTitle}>
															Extra Bed & Baby Cot
													</div>
													<ul>
															<li>Children 0 - 12 years occupying the same room with at least one adult and using existing bedding will not incur additional charges. Additional charges may apply if extra bed or linen are required.</li>
															<li>Baby Cot is free.</li>
													</ul>

													<div style={styles.subTitle}>
															Pets
													</div>
													<ul>
															<li>Pets are not allowed.</li>
													</ul>

													<div style={styles.subTitle}>
															Ground Floor / Connecting Room
													</div>
													<ul>
															<li>Requests for ground floor and/or connecting rooms are subject to availability and surcharge of $30 (excl. GST) per room per night will apply. Confirmation of ground floor rooms will only be available upon arrival. Room numbers will be assigned upon check-in.</li>
													</ul>

													<div style={styles.subTitle}>
							Parking
						</div>
						<ul>
							<li>Parking charges apply. Parking lot availability is on a first come first served basis.</li>
							<li>Parking lots are exclusively for D’Resort guests, multiple entry concession pass can be purchased at iCounter located at level 2 lobby (guests are required to provide vehicle IU number). Please visit <a target="_blank" href="https://www.dresort.com.sg/destination/getting-to-d'resort">https://www.dresort.com.sg/destination/getting-to-d'resort</a> or check with our friendly staff onsite.</li>
						</ul>

						<div style={styles.subTitle}>
							Wild Wild Wet Admission
						</div>
						<ul>
							<li>NTUC and nEbO members who book a room through D'Resort website are entitled unlimited access to Wild Wild Wet during their stay. Members must bring along their member card (Silver) upon check in; and Wild Wild Wet Day Passes will be issued based on the room occupancy and valid for the duration of stay.</li>
							<li>Wild Wild Wet closed on Tuesday. Tickets for Wild Wild Wet are non-transferable to another day. Refer to <a href="https://www.wildwildwet.com/visitor-information/guest-services" target="_blank">https://www.wildwildwet.com/visitor-information/guest-services</a> for latest operating hours.</li>
						</ul>

						<div style={styles.subTitle}>
								Catering Of Food
						</div>
										<ul>
												<li>Catering of food is only allowed through our appointed caterer. Please visit <a target="_blank" href="https://www.dresort.com.sg/explore/eat">https://www.dresort.com.sg/explore/eat</a> for more details. No other caterers are allowed in the resort premises.</li>
										</ul>

										<div className="modal-header">
												<h4 className="modal-title">Important Information</h4>
										</div>
										{/*<div style={styles.subTitle}>
												Important Information
										</div>*/}
										{generalInformationContent()}
										{/*<div style={styles.subTitle}>
												Privacy Policy
										</div>
										<div style={{textAlign:'left',margin:'15px 0px 0px 20px'}}>
												By accepting the term and condition, I have also read and accepted to the NTUC Club’s Privacy Policies http://www.ntucclub.com/privacy-policy/ which is incorporated into this Agreement by reference.
										</div>
										<br/>*/}
										<div className="modal-header">
												<h4 className="modal-title">Cancellation Policy</h4>
										</div>
										<div className="cancellation-policy">
												<div id="cancellation-policy">
														<div style={styles.subTitle}>
																Booking under { ratePlanName }
														</div>
														<div style={styles.policyText}>{ ratePolicy.name }</div>
														{ ratePolicy.is_non_refundable ?
																null :
											<div style={styles.policyText}>
																		Before { policyCancellationDate } (S$ { ratePolicy.penalty } admin fee per room applies)
																</div>
														}
														{ ratePolicy.description != '' ? <div style={styles.policyText}>{ ratePolicy.description }</div> : null }
												</div>
												<div style={styles.subTitle}>
														BBQ Pit Cancellation Policy
												</div>
												<ul>
														<li>In the event of cancellation or inclement weather, 100% of BBQ pit booking charges are strictly non-refundable.</li>
												</ul>
												<div style={styles.subTitle}>
														No-Show / Early Departure
												</div>
												<ul>
														<li>In the event of a no-show, early departure or if guests fail to check-in on their stipulated date of arrival, 100% of room charges are strictly non-refundable.</li>
												</ul>
										</div>
								</div>
						</div>
					</div>
				</div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let params = { grandTotal: 0, uniqueID: '', retrieveBookingStatus: false, bookingDetail: null }

	if ( state.book.result ) {
		if ( state.book.result.error ) {
			params = state.book.result;
		}
		else {
			params.grandTotal = state.book.result.grandTotal;
			params.uniqueID = state.book.result.uniqueID;
		}
    }

	if ( state.bookingDetail.result ) {
        params.retrieveBookingStatus = true;
        params.bookingDetail = state.bookingDetail.result.bookingDetail;
    }


    return params;
}

const mapDispatchToProps = (dispatch) => {
  	const { book, retrieveBookingWithToken } = allAPI;
  	return bindActionCreators({ book, retrieveBookingWithToken }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Book));
